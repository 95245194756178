.container {
    h2 {
        text-align: center;
        margin-bottom: 1rem;
    }

    .button {
        margin-top: 1rem;
        float: right;
    }

    caption p {
        float: right;
        font-weight: bold;
    }
}
